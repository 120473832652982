import React from 'react';
import {useState} from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import UserNavbar from '../components/UserNavbar'
import PublicNavbar from '../components/PublicNavbar'


const Navbar = () => {
  const [user, setUser] = useState(null);
  const auth = getAuth();

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(auth.currentUser)
    } else {
      setUser(null)
    }
  });

    return user ? <UserNavbar/> : <PublicNavbar/>
}
  export default Navbar;