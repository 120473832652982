import './App.css';
import { Fragment } from 'react';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Navbar from './components/Navbar';
import About from './pages/About';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Privacy from './pages/Privacy'
import CreateListing from './pages/CreateListing';
import ListingDetails from './pages/ListingDetails';
import Main from './pages/Main';
import MapMode from './pages/MapMode';
import ForgotPassword from './pages/ForgotPassword';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CountyListings } from './pages/CountyListings';
import MetaTags from './components/MetaTags';
import { UserProfile } from './pages/UserProfile';
import MainTest from './pages/MainTest';

function App() {
  return (
    <>
       <Router>
        <MetaTags />
        <Fragment>
          <Navbar />
          <Routes>
            <Route exact path='/' element={<About />} />
            <Route exact path='/explore' element={<Main />} />
            <Route exact path='/test11' element={<MainTest />} />
            <Route exact path='/explore/county/:countyName' element={<CountyListings />} />
            <Route exact path='/explore/:categoryName/:countyName/:listingId' element={<ListingDetails />} />
            <Route exact path='/map' element={<MapMode />} />
            <Route exact path='/sign-in' element={<SignIn />} />
            <Route exact path='/sign-up' element={<SignUp />} />
            <Route exact path='/about' element={<About />} />
            <Route exact path='/privacy' element={<Privacy />} />
            <Route exact path='/category/:categoryName/:listingId' element={<ListingDetails />} />
            <Route exact path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/create-listing' element={<PrivateRoute />}>
              <Route path='/create-listing' element={<CreateListing />} />
            </Route>
            <Route path='/user' element={<PrivateRoute />}>
              <Route path='/user' element={<UserProfile/>} />
            </Route>
          </Routes>
          <Footer />
        </Fragment>
      </Router>
      <ToastContainer
        position='top-center'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
