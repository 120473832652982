import React from 'react';
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
  MarkerClusterer,
} from '@react-google-maps/api';
import { Combobox, Transition } from '@headlessui/react';
import CountUp, { useCountUp } from 'react-countup';
import {
  ChartSquareBarIcon,
  LocationMarkerIcon,
} from '@heroicons/react/outline';
import { useEffect, useState, useRef, useCallback } from 'react';
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter,
} from 'firebase/firestore';
import { db } from '../firebase.config';
import { toast } from 'react-toastify';
import mapStyle from '../mapStyle';
import { Link } from 'react-router-dom';
import ImageLoader from '../components/ImageLoader';

const containerStyle = {
  width: 'inherit',
  height: 'inherit',
};
//53.1424° N, 7.6921° W Ireland
//TODO: Get the users location and automaitically center map there.
const center = {
  lat: 53.2734,
  lng: -7.7782,
};

//MarkerClusterer Options
const options = {
  imagePath:
    'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
};
const MapMode = () => {
  const [listings, setListings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(null);
  const [listingsNo, setListingsNo] = useState(null);

  useEffect(() => {
    const fetchListings = async () => {
      try {
        // fetch reference to the collection
        const listingsRef = collection(db, 'listings');

        // create a query
        const q = query(
          listingsRef,
          // TODO double check third parameter here
          // where('isDerelict', '==', true),
          orderBy('timestamp', 'desc')
        );

        // execute the query
        const querySnap = await getDocs(q);

        let listings = [];

        querySnap.forEach((doc) => {
          return listings.push({
            id: doc.id,
            data: doc.data(),
          });
        });

        setListings(listings);
        setLoading(false);
        setListingsNo(querySnap.size);
      } catch (error) {
        toast.error('Could Not Get Listings');
      }
    };
    fetchListings();
  }, []);

  function createKey(listing) {
    return listing.data.geolocation.lat + listing.data.geolocation.lng;
  }

  // useRef to prevent reloads
  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  // function to pan the map and place the pin
  const panTo = ({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(15);
  };

  //get the user location & pan to it
  const locateUser = () => {
    // Try HTML5 geolocation.
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // pan map to user location
          panTo({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => {
          toast.error('Location Not Available');
        }
      );
    } else {
      // Browser doesn't support Geolocation
      toast.error('Location Not Available');
    }
  };

  return (
    <>
      <div className='flex flex-row w-auto bg-yellow-400 h-12 border-black border-2 items-center justify-center font-mono text-lg uppercase'>
        <ChartSquareBarIcon className='w-6 h-6 block mr-3' />
        <CountUp end={listingsNo} duration={2.75} delay={0} className='mr-2' />
        <p>Properties Listed</p>
      </div>
      <div className='absolute z-10 flex flex-row w-screen h-12 items-center justify-center'>
        <div className=''>
          <button
            className='mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-indigo-600 hover:bg-indigo-50'
            onClick={locateUser}
          >
            <LocationMarkerIcon className='w-6 h-6 block mr-3' /> Doghouses Near
            Me
          </button>
        </div>
      </div>

      <div className='bg-gray-100 flex flex-col-reverse justify-center lg:flex-row h-auto flex-wrap w-auto'>
        <div className='w-screen h-screen'>
          <>
            <div className='h-screen'>
              <LoadScript
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
              >
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  onLoad={onMapLoad}
                  center={center}
                  zoom={6.5}
                  options={{
                    mapTypeControl: false,
                    disableDefaultUI: true,
                    zoomControl: true,
                    styles: mapStyle,
                    gestureHandling: 'greedy',
                  }}
                >
                  {/* Child components, such as markers, info windows, etc. */}

                  {listings && listings.length > 0 ? (
                    <>
                      <MarkerClusterer options={options}>
                        {(clusterer) =>
                          listings.map((listing) => (
                            <Marker
                              position={listing.data.geolocation}
                              clusterer={clusterer}
                              key={createKey(listing)}
                              //TODO: Fix this maps object cannot be found after a reload
                              icon={{
                                url: '/doghouse.svg',
                                scaledSize: new window.google.maps.Size(40, 40),
                                origin: new window.google.maps.Point(0, 0),
                                anchor: new window.google.maps.Point(20, 20),
                              }}
                              onClick={() => {
                                setSelected(listing);
                              }}
                            />
                          ))
                        }
                      </MarkerClusterer>
                    </>
                  ) : (
                    <Marker />
                  )}

                  {/* if a marker is slected return an InfoWindow */}
                  {selected ? (
                    <InfoWindow
                      position={selected.data.geolocation}
                      onCloseClick={() => {
                        setSelected(null);
                      }}
                    >
                      <div>
                        <Link
                          to={`/category/${selected.data.type}/${selected.id}`}
                        >
                          <div className='flex flex-col justify-center items-center'>
                          <ImageLoader
                            src={selected.data.thumbnailUrls ? selected.data.thumbnailUrls : selected.data.imgUrls}
                            className='rounded-lg object-scale-down max-h-24'
                            alt="Thumbnail"
                          />
                            <h1 className='flex flex-row  w-36 underline my-2 justify-center'>
                              {selected.data.town}
                            </h1>
                          </div>
                        </Link>
                      </div>
                    </InfoWindow>
                  ) : null}
                </GoogleMap>
              </LoadScript>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default MapMode;
