import React from 'react';
import Create from '../components/Create';
import ListingItemTest from '../components/ListingItemTest';
import Spinner from '../components/Spinner';
import ShareButton from '../components/ShareButton';
import ScrollTopButton from '../components/ScrollTopButton';
import InfiniteScroll from 'react-infinite-scroll-component';


const Listings = (props) => {
  //destrucutre
  const { listings, fetchMore, loading, currentFilter, numToShow } = props;

  return (
    <>
      {loading ? (
      
        <Spinner />
      ) : listings && listings.length > 0 ? (
        <>
          <InfiniteScroll
            dataLength={listings.length}
            next={fetchMore}
            hasMore={listings.length != numToShow}
            scrollThreshold={0.9}
            loader={<h4>Loading...</h4>}
            endMessage={
              <div className='flex flex-col items-center space-y-4'>
                <b>No more! You have seen it all</b>
                <ScrollTopButton />
              </div>
            }
            className='grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 space-y-2 space-x-2 overflow-y-auto h-screen overflow-x-hidden '
          >
            {listings.map((listing) => (
              <ListingItemTest listing={listing} key={listing.id} />
            ))}
          </InfiniteScroll>
        </>
      ) : (
        <div className='flex flex-row justify-center mt-4 w-screen'>
          <div className='flex flex-col items-center'>
            <p>🚫🏚️ No listings in {currentFilter} yet 😞</p>
            <br></br>
            <p>Help out and be the first to post one! 👆 </p>
            <br></br>
            <p>Or share with somebody who might 👇 </p>
            <br></br>
            <ShareButton />
            ...
            <p>Thank You! 🏡🌍 </p>
            <p> - Doghouse 💖 </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Listings;
