import React from 'react';
import { Helmet } from "react-helmet";
import { useEffect } from 'react';

const MetaTags = ({ title, description, url, imageUrl, twitterImageUrl }) => {

  // Set default values if no specific values are provided
  const defaultTitle = "Doghouse - Uncover Opportunity";
  const defaultDescription = "A place to make dereliction visible in cities, towns, and villages. We aim to uncover opportunities and initiate their development into living spaces.";
  const defaultUrl = "https://www.dog-house.ie";
  const defaultImageUrl = "https://www.dog-house.ie/doghouse192.png";
  const defaultTwitterImageUrl = "https://www.dog-house.ie/banner.jpeg";

  // Generate the complete URL based on the current route
  const currentUrl = `${defaultUrl}${window.location.pathname}`;

  // Use the provided values or fallback to default values
  const metaTitle = title || defaultTitle;
  const metaDescription = description || defaultDescription;
  const metaUrl = url || currentUrl;
  const metaImageUrl = imageUrl || defaultImageUrl;
  const metaTwitterImageUrl = twitterImageUrl || defaultTwitterImageUrl;

  return (
    <Helmet>
      {/* html attributes */}
      <html lang="en" amp />
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      
      
      {/* body attributes */}
      <body className="root" />
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />

      {/* Open Graph tags */}
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:url" content={metaUrl} />
      <meta property="og:image" content={metaImageUrl} />
      <meta property="og:type" content="website" />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={metaTwitterImageUrl} />
      <meta name="twitter:url" content={metaUrl} />

      {/* Canonical link tag */}
      <link rel="canonical" href={metaUrl} />
    </Helmet>
  );
};

export default MetaTags;
