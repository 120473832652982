import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
/* This example requires Tailwind CSS v2.0+ */
import {
  CloudUploadIcon,
  CameraIcon,
  LockClosedIcon,
  KeyIcon,
  RefreshIcon,
  LightBulbIcon,
  ServerIcon,
  ShieldCheckIcon,
} from '@heroicons/react/outline';
import logo from '../assets/g5954.png';
import NameModal from '../components/NameModal';

const features = [
  {
    name: 'Post',
    icon: CameraIcon,
    desc: 'Anyone can post a photo and location of a vacant or derelict property in their local area. Posting is anonymous.',
  },
  {
    name: 'Register',
    icon: LightBulbIcon,
    desc: 'Home-makers, builders, business owners, and local developers can browse for opportunites and indicate their interest in buying.',
  },
  {
    name: 'Claim',
    icon: KeyIcon,
    desc: 'Owners can monitor interest in their property and should they choose to sell - claim and see all the interested buyers.',
  },
  // {
  //   name: 'Regenerate',
  //   icon: RefreshIcon,
  //   desc: 'There is a dereliction epidemic in Ireland. This represents a huge opportunity for small-time developers, builders, craftspeople, business owners, and home-makers to utilise and develop the resources already built.',
  // }
];

function About() {
  const navigate = useNavigate();
  const [showNameModal, setShowNameModal] = useState(false);

  // Modal cancel button clicked
  const handleCancel = () => {
    setShowNameModal(false);
  };

  return (
    <>
      {/* Modals: 1. Register Interest , 2. Claim For Sale */}
      <NameModal showNameModal={showNameModal} handleCancel={handleCancel} />
      <div className='relative bg-white py-16 sm:py-24 lg:pt-16 lg:pb-8'>
        <div className='mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl'>
          <div className='flex justify-center items-center'>
            <img className='block h-16 w-auto' src={logo} alt='Workflow' />
            {/* <h1 className='block md:hidden text-xl font-black'>Doghouse</h1> */}
          </div>
          <h2 className='text-base font-semibold tracking-wider text-indigo-600 uppercase'>
            OUR MISSION
          </h2>
          <p className='mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl'>
            Uncover Opportunity
          </p>
          <p className='mt-5 max-w-prose mx-auto text-xl text-gray-500 select-all'>
            Doghouse is a place to make dereliction visible and uncover
            opportunities in cities, towns, and villages. We aim to help
            initiate the development of derelict and vacant properties into
            living spaces.
          </p>
          <div className='inline-flex rounded-md shadow my-10'>
            <a
              onClick={() => navigate('/explore')}
              className='cursor-pointer inline-flex items-center justify-center px-5 py-1 border border-transparent text-base font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-700 '
            >
              Explore
            </a>
          </div>

          <div className='mt-12'>
            <div className='grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3'>
              {features.map((feature) => (
                <div key={feature.name} className='pt-6'>
                  <div className='flow-root bg-gray-50 rounded-lg px-6 pb-8'>
                    <div className='-mt-6'>
                      <div>
                        <Link to={`/explore`}>
                          <span className='inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg cursor-pointer '>
                            <feature.icon
                              className='h-6 w-6 text-white transfrom'
                              aria-hidden='true'
                            />
                          </span>
                        </Link>
                      </div>
                      <h3 className='mt-8 text-lg font-medium text-gray-900 tracking-tight'>
                        {feature.name}
                      </h3>
                      <p className='mt-5 text-base text-gray-500'>
                        {feature.desc}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='bg-white'>
        <div className='max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-5 lg:px-8'>
          <h2 className='text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
            <span className='block'>Ready to Dive In?</span>
          </h2>
          <div className='mt-16 flex justify-center'>
            <div className='inline-flex rounded-md shadow'>
              <a
                onClick={() => navigate('/explore')}
                className='cursor-pointer inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700'
              >
                Explore Properties
              </a>
            </div>
          </div>
          <div
            className='cursor-pointer text-xs text-gray-600 hover:text-indigo-700 py-10'
            onClick={() => setShowNameModal(true)}
          >
            What's with the name?
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
