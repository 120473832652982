import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
/* This example requires Tailwind CSS v2.0+ */
import {
  CameraIcon,
  KeyIcon,
  LightBulbIcon
} from '@heroicons/react/outline';
import logo from '../assets/g5954.png';

const features = [
  {
    name: 'Post',
    icon: CameraIcon,
    desc: 'Anyone can post a photo and location of a vacant or derelict property in their local area. Posting is anomynous.',
  },
  {
    name: 'Register',
    icon: LightBulbIcon,
    desc: 'Home-makers, builders, business owners, and local developers can browse for opportunites and indicate their interest in buying.',
  },
  {
    name: 'Claim',
    icon: KeyIcon,
    desc: 'Owners can monitor interest in their property and should they choose to sell - claim and see all the interested buyers.',
  },
  // {
  //   name: 'Regenerate',
  //   icon: RefreshIcon,
  //   desc: 'There is a dereliction epidemic in Ireland. This represents a huge opportunity for small-time developers, builders, craftspeople, business owners, and home-makers to utilise and develop the resources already built.',
  // }
];

function About() {
  const navigate = useNavigate();
  const [showNameModal, setShowNameModal] = useState(false);

  // Modal cancel button clicked
  const handleCancel = () => {
    setShowNameModal(false);
  };

  return (
    <>
      <div className='relative bg-white py-16 sm:py-24 lg:pt-16 lg:pb-8'>
        <div className='mx-auto max-w-md px-4 text-left sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl'>
          <div className='flex justify-center items-center'>
            <img className='block h-16 w-auto' src={logo} alt='Workflow' />
            {/* <h1 className='block md:hidden text-xl font-black'>Doghouse</h1> */}
          </div>
          <h2 className='text-base font-semibold text-indigo-600 uppercase'>
            PRIVACY POLICY
          </h2>
          <p className='mt-5 mx-auto text-xs text-gray-500'>
            This privacy policy will explain how our organization uses the
            personal data we collect from you when you use our website. Topics:
            What data do we collect? How do we collect your data? How will we
            use your data? How do westore your data? Marketing What are your
            data protection rights? What are cookies? How do we use cookies?
            What types of cookies do we use? How to manage your cookies Privacy
            policies of other websites Changes to our privacy policy How to
            contact us How to contact the appropriate authorities
          </p>
          <h2 className='text-base font-semibold text-indigo-600 uppercase mt-4'>
            What Data Does Doghouse Collect?
          </h2>
          <p className='mt-5 mx-auto text-xs text-gray-500'>
            Doghouse collects the following data: Personal identification
            information (Name, email address, phone number, etc.) Property
            address
          </p>
          <h2 className='text-base font-semibold text-indigo-600 uppercase mt-4'>
            How Do We Collect Your Data?
          </h2>
          <p className='mt-5 mx-auto text-xs text-gray-500'>
            Register online or voluntarily posting on Doghouse Voluntarily
            complete a customer survey or provide feedback on any of our message
            boards or via email. Use or view our website via your browser’s
            cookies. Our Company may also receive your data/address data
            indirectly from the following sources: Google Maps API
          </p>
          <h2 className='text-base font-semibold text-indigo-600 uppercase mt-4'>
            How Will We Use Your Data?
          </h2>
          <p className='mt-5 mx-auto text-xs text-gray-500'>
            Doghouse collects your data so that we can: initiate development of
            properties and manage your account. Email you with special offers on
            other products and services we think you might like. When Doghouse
            processes a new user or listing, it may send your data to, and also
            use the resulting information from, a party claiming a property for
            sale.
          </p>
          <h2 className='text-base font-semibold text-indigo-600 uppercase mt-4'>
            How Do We Store Your Data?
          </h2>
          <p className='mt-5 mx-auto text-xs text-gray-500'>
            Doghouse securely stores your data at Firebase Platform
            (Google)services. Doghouse will keep your name,email, phone number,
            addresses for 5 years. Once this time period has expired, we will
            delete your data by modifying the database to remove your records.
          </p>
          <h2 className='text-base font-semibold text-indigo-600 uppercase mt-4'>
            What are your data protection rights?
          </h2>
          <p className='mt-5 mx-auto text-xs text-gray-500'>
            Our Company would like to make sure you are fully aware of all of
            your data protection rights. Every user is entitled to the
            following:
            <ul>
              <li>
                The right to access – You have the right to request Our Company
                for copies of your personal data. We may charge you a small fee
                for this service.
              </li>
              <li>
                The right to rectification – You have the right to request that
                Our Company correct any information you believe is inaccurate.
                You also have the right to request Our Company to complete the
                information you believe is incomplete.
              </li>
              <li>
                The right to erasure – You have the right to request that Our
                Company erase your personal data, under certain conditions.
              </li>
              <li>
                The right to restrict processing – You have the right to request
                that Our Company restrict the processing of your personal data,
                under certain conditions.
              </li>
              <li>
                The right to object to processing – You have the right to object
                to Our Company’s processing of your personal data, under certain
                conditions.
              </li>
              <li>
                The right to data portability – You have the right to request
                that Our Company transfer the data that we have collected to
                another organization, or directly to you, under certain
                conditions. If you make a request, we have one month to respond
                to you. If you would like to exercise any of these rights,
                please contact us at our email: info@dog-house.ie
              </li>
            </ul>{' '}
          </p>
          <h2 className='text-base font-semibold text-indigo-600 uppercase mt-4'>
            Cookies
          </h2>
          <p className='mt-5 mx-auto text-xs text-gray-500'>
            Cookies are text files placed on your computer to collect standard
            Internet log information and visitor behavior information. When you
            visit our websites, we may collect information from you
            automatically through cookies or similar technology.For further
            information, visit allaboutcookies.org.
          </p>
          <h2 className='text-base font-semibold text-indigo-600 uppercase mt-4'>
            How Do We Use Cookies?
          </h2>
          <p className='mt-5 mx-auto text-xs text-gray-500'>
            Doghouse uses cookies in a range of ways to improve your experience
            on our website, including:Keeping you signed in,Understanding how
            you use our website.
          </p>
          <h2 className='text-base font-semibold text-indigo-600 uppercase mt-4'>
            What Type of Cookies Do We Use?
          </h2>
          <p className='mt-5 mx-auto text-xs text-gray-500'>
            There are a number of different types of cookies, however, our
            website uses: Functionality – Our Company uses these cookies so that
            we recognize you on our website and remember your previously
            selected preferences. These could include what language you prefer
            and location you are in. A mix of first-party and third-party
            cookies are used. Advertising – Our Company uses these cookies to
            collect information about your visit to our website, the content you
            viewed, the links you followed and information about your browser,
            device, and your IP address. Our Company sometimes shares some
            limited aspects of this data with third parties for advertising
            purposes. We may also share online data collected through cookies
            with our advertising partners. This means that when you visit
            another website, you may be shown advertising based on your browsing
            patterns on our website.
          </p>
          <h2 className='text-base font-semibold text-indigo-600 uppercase mt-4'>
            How To Manage Cookies?
          </h2>
          <p className='mt-5 mx-auto text-xs text-gray-500'>
            You can set your browser not to accept cookies, and the above
            website tells you how to remove cookies from your browser. However,
            in a few cases, some of our website features may not function as a
            result.
          </p>
          <h2 className='text-base font-semibold text-indigo-600 uppercase mt-4'>
            Privacy policies of other websites
          </h2>
          <p className='mt-5 mx-auto text-xs text-gray-500'>
            The Doghouse website contains links to other websites. Our privacy
            policy applies only to our website, so if you click on a link to
            another website, you should read their privacy policy.
          </p>
          <h2 className='text-base font-semibold text-indigo-600 uppercase mt-4'>
            Changes to our privacy policy
          </h2>
          <p className='mt-5 mx-auto text-xs text-gray-500'>
            Doghouse keeps its privacy policy under regular review and places
            any updates on this web page. This privacy policy was last updated
            on 30 June 2022.
          </p>
          <h2 className='text-base font-semibold text-indigo-600 uppercase mt-4'>
            How To Contact Us
          </h2>
          <p className='mt-5 mx-auto text-xs text-gray-500'>
            If you have any questions about Our Company’s privacy policy, the
            data we hold on you, or you would like to exercise one of your data
            protection rights, please do not hesitate to contact us. Email us
            at: info@dog-house.ie
          </p>
          <h2 className='text-base font-semibold text-indigo-600 uppercase mt-4'>
            How to contact the appropriate authority
          </h2>
          <p className='mt-5 mx-auto text-xs text-gray-500'>
            Should you wish to report a complaint or if you feel that Our
            Company has not addressed your concern in a satisfactory manner, you
            may contact the Information Commissioner’s Office.
          </p>
        </div>
      </div>
    </>
  );
}

export default About;
