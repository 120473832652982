import React from 'react';
import { PlusIcon } from '@heroicons/react/solid';
import { useNavigate, useLocation } from 'react-router-dom';
import Create from '../components/Create';
import { Combobox, Transition } from '@headlessui/react';
import ListingsTest from './ListingsTest';
import CountUp, { useCountUp } from 'react-countup';
import { ChartSquareBarIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import {
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  where,
  orderBy,
  limit,
  startAfter,
} from 'firebase/firestore';
import { db } from '../firebase.config';
import { toast } from 'react-toastify';

const MainTest = () => {
  const [listings, setListings] = useState(null);
  const [listingsNo, setListingsNo] = useState(null);
  const [numToShow, setNumToShow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [lastFetchedListing, setLastFetchedListing] = useState(null);
  const [currentFilter, setCurrentFilter] = useState('All');

  const navigate = useNavigate();
  
  useEffect(() => {

    const fetchListings = async () => {
      try {
        // fetch reference to the collection
        const listingsRef = collection(db, 'listings');
        setLoading(true);
        let q = query(listingsRef, orderBy('timestamp', 'desc'), limit(10));
        // create a query to match the filter
        if (currentFilter != 'All') {
          q = query(
            listingsRef,
            orderBy('timestamp', 'desc'),
            limit(10),
            where('county_region', '==', currentFilter)
          );
        }

        // execute the query
        const querySnap = await getDocs(q);

        //Get total listings collection to measure length and display the total count
        const qTot = query(listingsRef);
        const querySnapTot = await getDocs(qTot);

        //Get current query length to inform infinite scroll component
        let qCurrent = query(
          listingsRef,
          where('county_region', '==', currentFilter)
        );
        const querySnapCurrent = await getDocs(qCurrent);

        const lastVisible = querySnap.docs[querySnap.docs.length - 1];
        setLastFetchedListing(lastVisible);
        let listings = [];

        querySnap.forEach((doc) => {
          return listings.push({
            id: doc.id,
            data: doc.data(),
          });
        });

        setListings(listings);
        setLoading(false);

        // Set the total count for display
        setListingsNo(querySnapTot.size);

        // Set the number of listings to be shown to inform Infinite Scroll component
        if (currentFilter != 'All') {
          // must be length of current query
          setNumToShow(querySnapCurrent.size);
        } else {
          setNumToShow(querySnapTot.size);
        }

        setLoading(false);
      } catch (error) {
        toast.error('Could Not Get Listings');
      }
    };
    fetchListings();
  }, [currentFilter]);

  // pagination/ load more

  const fetchMore = () => {
    onFetchMoreListings();
  };

  const onFetchMoreListings = async () => {
    try {
      // fetch reference to the collection
      const listingsRef = collection(db, 'listings');

      let q = query(
        listingsRef,
        orderBy('timestamp', 'desc'),
        startAfter(lastFetchedListing),
        limit(10)
      );

      // create a query to match the filter
      if (currentFilter != 'All') {
        q = query(
          listingsRef,
          orderBy('timestamp', 'desc'),
          startAfter(lastFetchedListing),
          limit(10),
          where('county_region', '==', currentFilter)
        );
      }

      // execute the query
      const querySnap = await getDocs(q);

      const lastVisible = querySnap.docs[querySnap.docs.length - 1];
      setLastFetchedListing(lastVisible);

      let listings = [];

      querySnap.forEach((doc) => {
        return listings.push({
          id: doc.id,
          data: doc.data(),
        });
      });

      setListings((prevState) => [...prevState, ...listings]);
      setLoading(false);
    } catch (error) {
      toast.error('Could Not Get Listings');
    }
  };

  const filters2 = [
    'All',
    'County Antrim',
    'County Armagh',
    'County Carlow',
    'County Cavan',
    'County Clare',
    'County Cork',
    'County Derry',
    'County Donegal',
    'County Down',
    'County Dublin',
    'County Fermanagh',
    'County Galway',
    'County Kerry',
    'County Kildare',
    'County Kilkenny',
    'County Laois',
    'County Leitrim',
    'County Limerick',
    'County Longford',
    'County Louth',
    'County Mayo',
    'County Meath',
    'County Monaghan',
    'County Offaly',
    'County Roscommon',
    'County Sligo',
    'County Tipperary',
    'County Tyrone',
    'County Waterford',
    'County Westmeath',
    'County Wexford',
    'County Wicklow',
  ];

  const handleFilterSelect = (filter) => {
    setLoading(true);
    setCurrentFilter(filter);
    navigate(`/explore/county/${filter}`);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  // State & functions to run autocomplete in the combobbox
  const [searchQuery, setSearchQuery] = useState('');
  const autocompleteFilters =
    searchQuery === ''
      ? filters2
      : filters2.filter((filter) => {
          return filter.toLowerCase().includes(searchQuery.toLowerCase());
        });

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const filteredListings = listings
    ? listings.filter((listing) => {
        switch (currentFilter) {
          case 'All':
            return true;
          case 'County Antrim':
            return listing.data.county_region === currentFilter;
          case 'County Armagh':
            return listing.data.county_region === currentFilter;
          case 'County Carlow':
            return listing.data.county_region === currentFilter;
          case 'County Cavan':
            return listing.data.county_region === currentFilter;
          case 'County Clare':
            return listing.data.county_region === currentFilter;
          case 'County Cork':
            return listing.data.county_region === currentFilter;
          case 'County Derry':
            return listing.data.county_region === currentFilter;
          case 'County Donegal':
            return listing.data.county_region === currentFilter;
          case 'County Down':
            return listing.data.county_region === currentFilter;
          case 'County Dublin':
            return listing.data.county_region === currentFilter;
          case 'County Fermanagh':
            return listing.data.county_region === currentFilter;
          case 'County Galway':
            return listing.data.county_region === currentFilter;
          case 'County Kerry':
            return listing.data.county_region === currentFilter;
          case 'County Kildare':
            return listing.data.county_region === currentFilter;
          case 'County Kilkenny':
            return listing.data.county_region === currentFilter;
          case 'County Laois':
            return listing.data.county_region === currentFilter;
          case 'County Leitrim':
            return listing.data.county_region === currentFilter;
          case 'County Limerick':
            return listing.data.county_region === currentFilter;
          case 'County Longford':
            return listing.data.county_region === currentFilter;
          case 'County Louth':
            return listing.data.county_region === currentFilter;
          case 'County Mayo':
            return listing.data.county_region === currentFilter;
          case 'County Meath':
            return listing.data.county_region === currentFilter;
          case 'County Monaghan':
            return listing.data.county_region === currentFilter;
          case 'County Offaly':
            return listing.data.county_region === currentFilter;
          case 'County Roscommon':
            return listing.data.county_region === currentFilter;
          case 'County Sligo':
            return listing.data.county_region === currentFilter;
          case 'County Tipperary':
            return listing.data.county_region === currentFilter;
          case 'County Tyrone':
            return listing.data.county_region === currentFilter;
          case 'County Waterford':
            return listing.data.county_region === currentFilter;
          case 'County Wexford':
            return listing.data.county_region === currentFilter;
          case 'County Wicklow':
            return listing.data.county_region === currentFilter;
          case 'County Westmeath':
            return listing.data.county_region === currentFilter;
        }
      })
    : null;

  return (
    <>
      <div className='bg-gray-100 p-2 md:p-5 h-auto w-auto '>
        <div className=''>
          <ListingsTest
            listings={filteredListings}
            fetchMore={fetchMore}
            loading={loading}
            currentFilter={currentFilter}
            numToShow={numToShow}
          />
        </div>
      </div>
    </>
  );
};

export default MainTest;
