import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase.config';
import Spinner from '../components/Spinner';
import RegisterModal from '../components/RegisterModal';
import { Helmet } from 'react-helmet';
import ClaimModal from '../components/ClaimModal';
import {
  ShareIcon,
  KeyIcon,
  LightBulbIcon,
  EyeIcon,
  SparklesIcon,
  PencilIcon,
  PuzzleIcon,
  MapIcon,
  MailIcon,
  HomeIcon,
  ClockIcon,
  ClipboardCheckIcon,
  QuestionMarkCircleIcon,
  ExclamationIcon,
  FlagIcon,
} from '@heroicons/react/outline';
import { HeartIcon as HeartIconSolid } from '@heroicons/react/solid';
import MiniMap from '../components/MiniMap';
import ShareButton from '../components/ShareButton';
import { toast } from 'react-toastify';
import MetaTags from '../components/MetaTags';
import LikeButton from '../components/LikeButton';

const ListingDetails = () => {
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(true);
  const [shareLinkCopied, setShareLinkCopied] = useState(null);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showClaimModal, setClaimModal] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const auth = getAuth();

 

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Oper

    const fetchListing = async () => {
      const docRef = doc(db, 'listings', params.listingId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setListing(docSnap.data());
        setLoading(false);
      }
    };

    fetchListing();
  }, [navigate.apply, params.listingId]);

  // update document on firestore, i.e. register user
  const updateDocument = async (updates) => {
    const docRef = doc(db, 'listings', params.listingId);
    try {
      const updatedDocument = await updateDoc(docRef, {
        registered: [
          ...listing.registered,
          {
            name: `${updates.name}`,
            email: `${updates.email}`,
            number: `${updates.number}`,
          },
        ],
      });
      
      toast.success('Buyers Interest Registered!');
      toast('We will email you if the property is claimed for sale.');
    } catch (error) {
      console.log(error);
      toast.error('Registration Failed ');
    }
  };

  // map container style
  const containerStyle = {
    width: 'inherit',
    height: 'inherit',
  };

  if (loading) {
    return <Spinner />;
  }

  const dateStamp = new Date(listing.timestamp.seconds * 1000);
  const dateLogged = new Intl.DateTimeFormat('en-UK', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(dateStamp);

  // Modal cancel button clicked
  const handleCancel = () => {
    setShowRegisterModal(false);
    setClaimModal(false);
  };

  // Register modal submit button clicked
  const handleRegisterSubmit = (updates) => {
    // update firestore document with the new interest
    // field: registered (array)
    updateDocument(updates);
    setShowRegisterModal(false);
  };

  // Claim modal submit button clicked
  const handleClaimSubmit = () => {
    setShowRegisterModal(false);
  };

  // Set the meta tags based on the listing data
  const metaTitle = `Dereliction in ${listing?.town ?? 'Unknown'}.`;
  const metaDescription = `A derelict property near ${listing?.town ?? ''} in ${listing?.county_region ?? ''}..`;
  const metaImageUrl = listing?.socialMediaCardUrls?.[0] || 'https://www.dog-house.ie/banner.jpeg';
  const metaUrl = window.location.href;
  return (
    <>
      <MetaTags
        title={metaTitle}
        description={metaDescription}
        imageUrl={metaImageUrl}
        url={metaUrl}
        twitterImageUrl={metaImageUrl}
      />
      {/* Modals: 1. Register Interest , 2. Claim For Sale */}
      <RegisterModal
        showRegisterModal={showRegisterModal}
        handleCancel={handleCancel}
        handleRegisterSubmit={handleRegisterSubmit}
      />

      <ClaimModal
        showClaimModal={showClaimModal}
        handleCancel={handleCancel}
        handleSubmit={handleClaimSubmit}
        listing={listing}
      />
      <div className='flex flex-col h-auto w-auto items-center'>
        <div className='flex flex-col lg:flex-row lg:items-start items-center'>
          <div className='flex w-auto lg:w-2/3 lg:justify-center p-4'>
            {/* TODO: Make an image slider for multiple images */}
            <img
              src={listing.imgUrls[0]}
              alt='property image'
              className='rounded-lg object-scale-down max-h-96'
            />
            
          </div>
          
          {/* Information */}
          <div className='flex flex-col items-center lg:items-start'>
            <div className='mt-4 flex text-xl font-semibold text-center lg:text-left '>
             {listing.town}
            </div>
            <div className='flex text-3xl text-center lg:text-left font-normal'>
             {listing.county_region ==='Northern Ireland' ? listing.town : listing.county_region}
            </div>
            <button
                type='button'
                className='relative inline-flex items-center px-2 py-1 md:px-4 md:py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500'
                onClick={() => navigate(`/explore/county/${listing.county_region}`)}
              >
                <HomeIcon className='-ml-1 mr-2 h-5 w-5' aria-hidden='true' />
                <span>See More From {listing.county_region}</span>
              </button>

            {/* TODO: Claim property for sale or rental div is hidden*/}
            <div className='flex flex-col items-center justify-center lg:flex-row space-y-2 lg:space-y-0 mb-4 mt-8'>
              <button
                type='button'
                className='relative inline-flex items-center px-2 py-1 md:px-4 md:py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-pink-500 hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500'
                onClick={() => setClaimModal(true)}
              >
                <KeyIcon className='-ml-1 mr-2 h-5 w-5' aria-hidden='true' />
                <span>Claim Property For Sale</span>
              </button>
              
              {/* <button
                type='button'
                className='hidden relative inline-flex items-center px-2 py-1 md:px-4 md:py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500 '
                onClick={() => setShowClaimModal(true)}
              >
                <HomeIcon className='-ml-1 mr-2 h-5 w-5' aria-hidden='true' />
                <span>Claim Property For Rental</span>
              </button> */}
            </div>
            
            <div className=' flex flex-col items-center lg:flex-row space-y-2 lg:space-y-0'>
              {/* TODO: Register interest func, div is hidden*/}
              <div className='flex'>
                
                <button
                  type='button'
                  className='relative inline-flex items-center px-2 py-1 md:px-4 md:py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500'
                  onClick={() => setShowRegisterModal(true)}
                >
                  <PencilIcon
                    className='-ml-1 mr-2 h-5 w-5'
                    aria-hidden='true'
                  />
                  <span>Register Interest</span>
                </button>
              </div>
              <div className='flex px-4'>
                <ShareButton />
              </div>
            </div>
            
            <div className='flex flex-col mt-6 transform overflow-hidden px-4 py-4 sm:px-6 rounded-md shadow-2xl hover:-translate-y-0.5 h-auto w-full items-center'>
              {/* TODO: Add registered interest func */}

              <div className=' flex flex-row items-start my-1 underline justify-center text-center font-semibold w-max'>
                Information
              </div>
            
              {listing.registered.length == 1 ? (
                <div
                  className='hover:bg-green-100 hover:border-black flex flex-row items-start my-1 border-2 px-2 border-red-600 rounded-lg cursor-pointer'
                  onClick={() => setClaimModal(true)}
                >
                  <MailIcon className='w-6 h-6 block mx-1 text-pink-600' />
                  <h1 className='font-thin'>
                    {listing.registered.length} Buyer Waiting
                  </h1>
                </div>
              ) : listing.registered.length >= 2 ? (
                <div
                  className='hover:bg-green-100 hover:border-black flex flex-row items-start my-1 border-2 px-2 border-red-600 rounded-lg cursor-pointer'
                  onClick={() => setClaimModal(true)}
                >
                  <MailIcon className='w-6 h-6 block mx-2 text-pink-600' />
                  <h1 className='font-thin'>
                    {listing.registered.length} Buyers Waiting
                  </h1>
                </div>
              ) : null}
              {/* TODO: Add view func */}
              <div className=' hidden flex flex-row items-start my-1'>
                <EyeIcon className='w-6 h-6 block mx-2' />
                Views
              </div>
              <div className='flex flex-col w-1/2 justify-center'>
              {listing.likes > 0 ? (
                <div className='flex flex-row items-center my-1'>
                  <div className='w-6 h-6 block mx-2'>❤️</div>
                  <p> {listing.likes} 
                  {listing.likes == 1 ? " Like" : " Likes"}</p>
                </div>
              ) : (
                <></>
              )}
              <div className='flex flex-row items-start my-1'>
                <div className='w-6 h-6 block mx-2'>⏱️</div>
                {dateLogged}
              </div>
              {listing.type === 'open' ? (
                <div className='flex flex-row items-center my-1'>
                  <div className='w-6 h-6 block mx-2'>⚠️</div> Unclaimed
                </div>
              ) : (
                <></>
              )}
              {listing.isDerelict ? (
                <div className='flex flex-row items-center my-1'>
                  <div className='w-6 h-6 block mx-2'>🏚️</div>
                  <p>Appears Derelict</p>
                </div>
              ) : (
                <></>
              )}
              {/* {listing.ownerKnown ? (
                <div className='flex flex-row items-center my-1'>
                  <div className='w-6 h-6 block mx-2'>😃</div>
                  <p>Owner Known</p>
                </div>
              ) : (
                <div className='flex flex-row items-center my-1'>
                  <div className='w-6 h-6 block mx-2'>❓</div>
                  <p>Owner Unknown</p>
                </div>
              )} */}
              {listing.isVacant ? (
                <div className='flex flex-row items-center my-1'>
                  <div className='w-6 h-6 block mx-2'>🏝️</div> <p> Appears Vacant</p>
                </div>
              ) : (
                <>
                </>
              )}
              
              <div className='flex flex-row items-center my-1'>
                  <div className='w-6 h-6 block mx-2 '>ℹ️</div>
                  <p className='text-gray-500 wrap text-xs'>All information is based on the users judgement and how the property appears to them. The circumstances behind every property are unknown and unconfirmed.</p>
                </div>
            </div>
            </div>
            
          </div>
        </div>

        <div className='my-6 flex flex-row font-bold text-xl items-center'>
          <MapIcon className='w-6 h-6 mx-2 ' />
          <p className='text-lg'> Location</p>
        </div>
        <div className='flex flex-row h-96 w-full px-5  lg:items-start'>
          <MiniMap listing={listing} />
        </div>
      </div>
    </>
  );
};

export default ListingDetails;
