import { useState, useEffect, useRef, useCallback } from 'react';
import {
  LocationMarkerIcon,
  SaveIcon,
  CameraIcon,
  ThumbUpIcon,
  PencilAltIcon,
  ClipboardCheckIcon,
} from '@heroicons/react/solid';
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  Autocomplete,
} from '@react-google-maps/api';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import {
  addDoc,
  collection,
  serverTimestamp,
  updateDoc,
  setDoc,
  increment,
  doc,
} from 'firebase/firestore';
import { db } from '../firebase.config';
import { useNavigate } from 'react-router-dom';
import Spinner from '../components/Spinner';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import mapStyle from '../mapStyle';
import { CheckCircleIcon } from '@heroicons/react/outline';
import {useImageUpload} from '../hooks/useDerelictImageUpload';

function CreateListing() {
  const geofire = require('geofire-common');
  const [loading, setLoading] = useState(false);
  const [userCheckAddress, setUserCheckAddress] = useState(false);
  const [userEditAddress, setUserEditAddress] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const {uploadDerelictImage, loadingImages, progress} = useImageUpload();
  const [formData, setFormData] = useState({
    type: 'open',
    address: '',
    userAddress: '',
    town: '',
    county_region: '',
    country: '',
    geolocation: {},
    geohash: '',
    isVacant: false,
    isDerelict: false,
    ownerKnown: false,
    images: {},
  });

  // maps api

  //infobox options
  const options = { closeBoxURL: '', enableEventPropagation: true };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  // destrucutre from formData
  const {
    type,
    address,
    userAddress,
    town,
    county_region,
    country,
    isDerelict,
    isVacant,
    geolocation,
    geohash,
    images,
  } = formData;

  // map container style
  const containerStyle = {
    width: 'inherit',
    height: 'inherit',
  };

  // get the Google Places API for LoadScript component
  const libraries = ['places'];

  //default map center
  //53.1424° N, 7.6921° W Ireland
  //TODO: Get the users location and automaitically center map there.
  const [mapCenter, setMapCenter] = useState({
    lat: 53.2734,
    lng: -7.7782,
  });
  const [markerPosition, setMarkerPosition] = useState(null);

  const auth = getAuth();
  const navigate = useNavigate();
  const isMounted = useRef(true);

  useEffect(() => {
    if (isMounted) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setFormData({ ...formData, userRef: user.uid, country: 'Ireland' });
        } else {
          navigate('/sign-in');
        }
      });
    }

    return () => {
      isMounted.current = false;
    };

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  // useRef to prevent reloads
  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  // function to pan the map and place the pin
  const panTo = ({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(15);
    setMarkerPosition({ lat, lng });
  };

  //get the user location & pan to it
  const locateUser = () => {
    // Try HTML5 geolocation.
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // pan map to user location
          panTo({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });

          // get & set the latitude & longitude
          geolocation.lat = position.coords.latitude;
          geolocation.lng = position.coords.longitude;
        },
        () => {
          toast.error('Location Not Available');
        }
      );
    } else {
      // Browser doesn't support Geolocation
      toast.error('Location Not Available');
    }
  };


  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // submission checks
    if (!isDerelict && !isVacant) {
      setLoading(false);
      toast.error('Please only post vacant and/or derelict properties');
      return;
    }

    if (images.length > 1) {
      setLoading(false);
      toast.error('Max 1 image');
      return;
    }

    if (address === '') {
      setLoading(false);
      toast.error('Get The Address & Confirm');
      return;
    }

    // Auto-generate an ID for a new sales listing
    const newDerelictListingRef = doc(collection(db, "listings"));
    // Get the ID (saleId)
    const newDerelictListingID = newDerelictListingRef.id;

    // call storeImage fucntion for all images to be uploaded
    const urls = await Promise.all(
      [...images].map((image) => uploadDerelictImage(image, newDerelictListingID))
    ).catch(() => {
      setLoading(false);
      toast.error('There was a problem uploading the images.');
    });

    const imgs = urls.map(urlObj => urlObj.imageUrl);
    const thumbnails = urls.map(urlObj => urlObj.thumbnailUrl);
    const socials = urls.map(urlObj => urlObj.socialMediaCardUrl);

    // ... do something with imgUrls and thumbnailUrls
    console.log('Image URLs:', imgs);
    console.log('Thumbnail URLs:', thumbnails)

    // CREATE THE OBJECT TO BE SAVED TO DATABASE
    const saveCopy = {
      ...formData,
      registered: [],
      imgUrls: imgs,
      thumbnailUrls: thumbnails,
      socialMediaCardUrls: socials,
      likes: 0,
      views: 0,
      timestamp: serverTimestamp(),
    };
    // CLEAN-UP
    delete saveCopy.images;
    const docRef = await setDoc(doc(db, 'listings', newDerelictListingID), saveCopy);

    // Increment listingCount Stat using a counter
    const incrementCount = increment(1);  // Use firebase/firestore FieldValue.increment
    const statsRef = doc(db, 'listings', '--stats--');  // Get a DocumentReference to the stats document
    await updateDoc(statsRef, {
        listingCount: incrementCount,
    });

    setLoading(false);
    toast.success('Property Recorded');
    toast('Thank You For Contributing!');
    navigate('/explore');
  };

  const onMutate = (e) => {
    //Files
    if (e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        images: e.target.files,
      }));

      // Manipulate UI to show file names uploaded
      const output = document.querySelector('#output');
      const instruct = document.querySelector('#yurt');
      function logFilenames() {
        instruct.innerHTML = '';
        const fileInput = document.querySelector('#file-upload');
        const files = fileInput.files;
        const fileListLength = files.length;
        for (let i = 0; i < fileListLength; i++) {
          output.innerText = `${output.innerText}\n${files.item(i).name}`;
        }
      }

      logFilenames();
      return;
    }

    //Text/Booleans/Number
    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.value,
      }));
    }

    //Checkboxes
    if (e.target.checked == true && e.target.type == 'checkbox') {
      //checkbox was checked
      setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: true,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: false,
      }));
    }
  };

  // onChange method
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //MAP LOCATOR FUNCTIONS
  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  const getCountyRegion = (addressArray) => {
    let state = '';
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          'administrative_area_level_1' === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  /**
   * Get the area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  const getArea = (addressArray) => {
    let area = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            'postal_town' === addressArray[i].types[j] ||
            'locality' === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };

  const getCountry = (addressArray) => {
    let country = '';
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          'country' === addressArray[i].types[0]
        ) {
          country = addressArray[i].long_name;
          return country;
        }
      }
    }
  };

  const onDrag = async (e) => {
    // set marker to where user drags
    setMarkerPosition({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
    //recenter map to where user clicks
    setMapCenter({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });

    //set geolocation
    geolocation.lat = e.latLng.lat();
    geolocation.lng = e.latLng.lng();
  };

  // when uer selects address from dropdown
  const handleSelect = async () => {
    const place = autocomplete.getPlace();

    // get & set the latitude & longitude
    geolocation.lat = place.geometry.location.lat();
    geolocation.lng = place.geometry.location.lng();

    //call panTo to move map and zoom
    panTo({ lat: geolocation.lat, lng: geolocation.lng });
  };

  //finally take geolcation and set all address state once

  const saveLocation = async () => {
    // check if geolocation has been set
    if (Object.keys(geolocation).length === 0) {
      toast('Place a pin on the map - search or click Find My Location');
      return;
    }

    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${geolocation.lat},${geolocation.lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&`
    );

    const data = await response.json();

    //set the geolocation state to current click location
    geolocation.lat = data.results[0]?.geometry.location.lat ?? 0;
    geolocation.lng = data.results[0]?.geometry.location.lng ?? 0;

    //get the geohash
    const hash = geofire.geohashForLocation([geolocation.lat, geolocation.lng]);

    //get the address components array
    const addressArray = data.results[0].address_components;

    //set the country in form state
    const country = getCountry(addressArray);

    //set the county/region
    const region = getCountyRegion(addressArray);

    //set the city/town
    const city_town = getArea(addressArray);

    //set address
    const address =
      data.results[0].formatted_address ?? data.results[1].formatted_address;

    setFormData((prevState) => ({
      ...prevState,
      country: country,
      county_region: region,
      town: city_town,
      address: address,
      userAddress: address,
      geohash: hash,
    }));

    toast.success('Location Saved');
    toast.success('Please Double Check');
    setUserCheckAddress(true);
  };

  const checkedSave = () => {
    setUserEditAddress((prevState) => !prevState);
    toast('Address Confirmed');
  };

  if (!isLoaded || loading) {
    return <Spinner />;
  }

  return (
    <>
      <div className='bg-gray-100'>
        <form method='POST' onSubmit={onSubmit}>
          {/* TODO: Add step/progress bar for form completion success */}
          <div className='px-5 py-10'>
            <div className='md:grid md:grid-cols-3 md:gap-6 '>
              <div className='md:col-span-1'>
                <div className='px-4 sm:px-0'>
                  <h3 className='text-lg font-medium leading-6 text-gray-900'>
                    Photo
                  </h3>
                  <p className='mt-1 text-sm text-gray-600'>
                    Please post a photo which clearly shows the whole property
                    from the public road. No need to trespass.
                  </p>
                </div>
              </div>
              <div className='mt-5 md:mt-0 md:col-span-2'>
                <div className='shadow sm:rounded-md sm:overflow-hidden'>
                  <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
                    <div>
                      <label className='block text-sm font-medium text-gray-700'>
                        Add Photo
                      </label>
                      {/* TODO: make the div below able to read drag and drop mouse events fo larger surface area file input */}
                      <div className='mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'>
                        <div className='space-y-1 text-center'>
                          <svg
                            className='mx-auto h-12 w-12 text-gray-400'
                            stroke='currentColor'
                            fill='none'
                            viewBox='0 0 48 48'
                            aria-hidden='true'
                          >
                            <path
                              d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                          <div className='flex text-sm text-gray-600'>
                            <label
                              htmlFor='file-upload'
                              className='relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                            >
                              <span>Upload a file</span>
                              <input
                                id='file-upload'
                                name='file-upload'
                                type='file'
                                onChange={onMutate}
                                className='sr-only'
                                max='1'
                                accept='.jpg,.png,.jpeg,image/png,image/jpeg,image/jpg'
                                required
                              />
                            </label>
                            <p className='pl-1'>or drag and drop</p>
                          </div>
                          <div id='yurt'>
                            <p className='text-xs text-gray-500'>
                              PNG, JPG, up to 6MB
                            </p>
                            <p className='text-xs text-gray-500'>
                              1 photo only - make it good!
                            </p>
                          </div>
                          <div
                            className='text-xs text-gray-500'
                            id='output'
                          ></div>
                        </div>
                      </div>
                      {/* TODO: Add camera upload option here - wireframed & ready below, hook up functions, make sure at least one file from with input is used remove required field from input above and check elsewhere.*/}
                      {/* <div className='flex flex-row justify-center my-2'>
                        <label
                          htmlFor='camera-input'
                          className='relative cursor-pointer'
                        >
                          <span>
                            <div className='flex flex-col items-center'>
                              <p className='text-xs text-gray-500'>or</p>
                              <CameraIcon className='w-10 h-10 block text-indigo-600 hover:text-indigo-500' />
                              <p className='text-xs text-gray-500'>
                                {' '}
                                Use Camera
                              </p>
                            </div>
                          </span>
                          <input
                            id='camera-input'
                            type='file'
                            capture='environment'
                            className='sr-only'
                            max='1'
                            accept='.jpg,.png,.jpeg,image/png,image/jpeg,image/jpg'
                          />
                        </label>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='hidden sm:block' aria-hidden='true'>
            <div className='py-5'>
              <div className='border-t border-gray-200' />
            </div>
          </div>

          <div className='mt-5 sm:mt-0 px-5 '>
            <div className='md:grid md:grid-cols-3 md:gap-6'>
              <div className='md:col-span-1'>
                <div className='px-4 sm:px-0'>
                  <h3 className='text-lg font-medium leading-6 text-gray-900'>
                    Site Location
                  </h3>
                  <p className='mt-1 text-sm text-gray-600'>
                    Go to your location or type the address into the search bar.
                  </p>
                  <span></span>
                </div>
              </div>
              <div className='mt-5 md:mt-0 md:col-span-2 bg-white'>
                <div className='shadow overflow-hidden sm:rounded-md'>
                  <div className='px-2 py-2 sm:p-6'>
                    <div className='flex flex-row justify-center mt-2'>
                      <button
                        type='button'
                        className='relative inline-flex items-center px-2 py-1 md:px-4 md:py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-igray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500'
                        onClick={locateUser}
                      >
                        <LocationMarkerIcon
                          className='-ml-1 mr-2 h-5 w-5'
                          aria-hidden='true'
                        />
                        <span>Find My Location</span>
                      </button>
                    </div>
                    <div className='flex flex-row justify-center mt-2 my-2 font-extralight'>
                      or
                    </div>
                    <div>
                      <Autocomplete
                        onLoad={(autocomplete) => setAutocomplete(autocomplete)}
                        onPlaceChanged={handleSelect}
                        bounds={mapCenter}
                      >
                        <input
                          type='text'
                          placeholder='Search Address'
                          className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm caret-pink-500 mb-2'
                        />
                      </Autocomplete>
                    </div>

                    <div className='h-96'>
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        onLoad={onMapLoad}
                        center={mapCenter}
                        zoom={6.0}
                        options={{
                          styles: mapStyle,
                          // draggableCursor: 'crosshair',
                          mapTypeControl: false,
                          disableDefaultUI: true,
                          zoomControl: true,
                        }}
                      >
                        {/* if a marker is return an InfoWindow */}
                        {markerPosition ? (
                          <InfoWindow
                            position={markerPosition}
                            onCloseClick={() => {}}
                          >
                            <div>
                              <h2>Drag Pin To Location</h2>
                            </div>
                          </InfoWindow>
                        ) : null}
                        <Marker
                          position={markerPosition}
                          draggable={true}
                          onDragEnd={onDrag}
                          title={'Drag To Location'}
                        />
                      </GoogleMap>
                    </div>
                  </div>
                  <div className='flex flex-row justify-center mx-4 my-4'>
                    <button
                      type='button'
                      className='relative inline-flex items-center px-2 py-1 md:px-4 md:py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-igray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500'
                      onClick={saveLocation}
                    >
                      <SaveIcon
                        className='-ml-1 mr-2 h-5 w-5'
                        aria-hidden='true'
                      />
                      <span>Get Address</span>
                    </button>
                  </div>

                  {userCheckAddress ? (
                    <div className='flex flex-row justify-center mx-4 my-4'>
                      <div className=' w-full'>
                        <label
                          htmlFor='email'
                          className='block text-sm font-medium text-gray-700'
                        >
                          {!userEditAddress ? (
                            <h1>Check Address</h1>
                          ) : (
                            <div className='text-green-500 font-bold flex flex-row'>
                              Confirmed Address{' '}
                              <CheckCircleIcon
                                className='ml-1 mr-2 h-5 w-5 text-green-500'
                                aria-hidden='true'
                              />
                            </div>
                          )}
                        </label>
                        <div className='mt-1 w-full'>
                          <input
                            name='userAddress'
                            type='address'
                            value={userAddress}
                            onChange={onChange}
                            required
                            disabled={userEditAddress}
                            className='w-full block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-xs sm:text-sm caret-pink-500 disabled:bg-slate-200 disabled:border-slate-200 disabled:shadow-none disabled:font-bold disabled:text-slate-200'
                          />
                        </div>
                        <div className='flex flex-row justify-center mx-4 my-4'>
                          <button
                            type='button'
                            className=' relative inline-flex items-center px-2 py-1 md:px-4 md:py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500'
                            onClick={checkedSave}
                          >
                            {!userEditAddress ? (
                              <div className='flex'>
                                <ThumbUpIcon
                                  className='-ml-1 mr-2 h-5 w-5'
                                  aria-hidden='true'
                                />
                                <span>Confirm Address</span>
                              </div>
                            ) : (
                              <div className='flex'>
                                <PencilAltIcon
                                  className='-ml-1 mr-2 h-5 w-5 '
                                  aria-hidden='true'
                                />
                                <span className=''>Edit Address</span>
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className='hidden sm:block' aria-hidden='true'>
            <div className='py-5'>
              <div className='border-t border-gray-200' />
            </div>
          </div>

          <div className='mt-10 sm:mt-0'>
            <div className='md:grid md:grid-cols-3 md:gap-6 px-5'>
              <div className='md:col-span-1'>
                <div className='px-4 sm:px-0'>
                  <h3 className='text-lg font-medium leading-6 text-gray-900'>
                    Information Checklist
                  </h3>
                  <p className='mt-1 text-sm text-gray-600'>
                    Please check the following points as appropriate and to the
                    best of your knowledge.
                  </p>
                </div>
              </div>
              <div className='mt-5 md:mt-0 md:col-span-2'>
                <div className='shadow overflow-hidden sm:rounded-md'>
                  <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
                    <legend className='text-base font-medium text-gray-900'>
                      Checklist
                    </legend>
                    <div className='mt-4 space-y-4'>
                      <div className='flex items-start'>
                        <div className='flex items-center h-5'>
                          <input
                            id='isVacant'
                            name='isVacant'
                            type='checkbox'
                            onClick={onMutate}
                            className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded'
                          />
                        </div>
                        <div className='ml-3 text-sm'>
                          <label
                            htmlFor='isVacant'
                            className='font-medium text-gray-700'
                          >
                            Vacant
                          </label>
                          <p className='text-gray-500'>
                            The property appears to be vacant for at least 3
                            months.
                          </p>
                        </div>
                      </div>
                      <div className='flex items-start'>
                        <div className='flex items-center h-5'>
                          <input
                            id='isDerelict'
                            name='isDerelict'
                            type='checkbox'
                            className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded'
                            onClick={onMutate}
                          />
                        </div>
                        <div className='ml-3 text-sm'>
                          <label
                            htmlFor='isDerelict'
                            className='font-medium text-gray-700'
                          >
                            Derelict
                          </label>
                          <p className='text-gray-500'>
                            The property appears to be derelict.
                          </p>
                        </div>
                      </div>
                      <div className='flex items-start'>
                        <div className='flex items-center h-5'>
                          <input
                            id='ownerKnown'
                            name='ownerKnown'
                            type='checkbox'
                            onClick={onMutate}
                            className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded'
                          />
                        </div>
                        <div className='ml-3 text-sm'>
                          <label
                            htmlFor='ownerKnown'
                            className='font-medium text-gray-700'
                          >
                            Owner
                          </label>
                          <p className='text-gray-500'>
                            The owner of the property is known.
                          </p>
                        </div>
                      </div>
                      {/*TODO: This notification email would be good to add */}
                      {/* <div className='flex items-start'>
                        <div className='flex items-center h-5'>
                          <input
                            id='offers'
                            name='offers'
                            type='checkbox'
                            className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded'
                          />
                        </div>
                        <div className='ml-3 text-sm'>
                          <label
                            htmlFor='offers'
                            className='font-medium text-gray-700'
                          >
                            Notify Me
                          </label>
                          <p className='text-gray-500'>
                            Get notified if a seller claims the property.
                          </p>
                        </div> 
                      </div> */}
                    </div>
                  </div>
                  <div className='px-4 py-6 bg-white text-right sm:px-6 flex justify-center'>
                    <button
                      type='submit'
                      className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transform  hover:-translate-y-0.5'
                    >
                      <ClipboardCheckIcon
                        className='-ml-1 mr-2 h-5 w-5'
                        aria-hidden='true'
                      />
                      <span>Submit</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default CreateListing;
