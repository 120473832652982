import React, { useEffect, useState, useCallback } from 'react';
import placeholderImg from '../assets/placeholder.png'
import errorImg from '../assets/error_img.png'

const ImageLoader = (props) => {
  const { src } = props;
  const [imgSrc, setSrc] = useState(placeholderImg || src);

  const onLoad = useCallback(() => {
    setSrc(src);
  }, [src]);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.addEventListener('load', () => {
      setSrc(src);
    });
    img.addEventListener('error', () => {
      setSrc(errorImg || placeholderImg);
    });
  }, [src, placeholderImg, errorImg]);

  return <img {...props} src={imgSrc} />;
};

export default ImageLoader;
