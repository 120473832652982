import React from 'react'
import { ChartSquareBarIcon } from '@heroicons/react/outline';
import Listings from './Listings';
import CountUp from 'react-countup';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  where,
  orderBy,
  limit,
  startAfter,
} from 'firebase/firestore';
import { db } from '../firebase.config';
import { toast } from 'react-toastify';

export const CountyListings = () => {

  const [listings, setListings] = useState(null);
  const [listingsNo, setListingsNo] = useState(null);
  const [numToShow, setNumToShow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [lastFetchedListing, setLastFetchedListing] = useState(null);
  const [currentFilter, setCurrentFilter] = useState('All');
  const { countyName }  = useParams();
  
  useEffect(() => {
    setCurrentFilter(countyName)
    fetchListings(countyName);
  
  }, [countyName]);


  const fetchListings = async (countyName) => {
    try {
      // fetch reference to the collection
      const listingsRef = collection(db, 'listings');
      setLoading(true);
      let q = query(listingsRef, orderBy('timestamp', 'desc'), limit(10));
      // create a query to match the filter
      
        q = query(
          listingsRef,
          orderBy('timestamp', 'desc'),
          limit(10),
          where('county_region', '==', countyName)
        );
      

      // execute the query
      const querySnap = await getDocs(q);

      //Get total listings collection to measure length and display the total count
      const qTot = query(listingsRef);
      const querySnapTot = await getDocs(qTot);

      //Get current query length to inform infinite scroll component
      let qCurrent = query(
        listingsRef,
        where('county_region', '==', countyName)
      );

      const querySnapCurrent = await getDocs(qCurrent);
      const lastVisible = querySnap.docs[querySnap.docs.length - 1];
      setLastFetchedListing(lastVisible);
      let listings = [];

      querySnap.forEach((doc) => {
        return listings.push({
          id: doc.id,
          data: doc.data(),
        });
      });

      setListings(listings);
      setLoading(false);

      // Set the total count for display
      setListingsNo(querySnapCurrent.size);

      // Set the number of listings to be shown to inform Infinite Scroll component
      if (currentFilter != 'All') {
        // must be length of current query
        setNumToShow(querySnapCurrent.size);
      } else {
        setNumToShow(querySnapTot.size);
      }

      setLoading(false);
    } catch (error) {
      toast.error('Could Not Get Listings');
    }
  };



  const fetchMore = () => {
    onFetchMoreListings();
  };

  const onFetchMoreListings = async () => {
    try {
      // fetch reference to the collection
      const listingsRef = collection(db, 'listings');

      let q = query(
        listingsRef,
        orderBy('timestamp', 'desc'),
        startAfter(lastFetchedListing),
        limit(10),
        where('county_region', '==', countyName)
      );

      // execute the query
      const querySnap = await getDocs(q);
      const lastVisible = querySnap.docs[querySnap.docs.length - 1];
      setLastFetchedListing(lastVisible);

      let listings = [];

      querySnap.forEach((doc) => {
        return listings.push({
          id: doc.id,
          data: doc.data(),
        });
      });

      setListings((prevState) => [...prevState, ...listings]);
      setLoading(false);
    } catch (error) {
      toast.error('Could Not Get Listings');
    }
  };
  
  return (
    <>
    <div className='flex flex-row w-auto bg-red-400 h-12 border-black border-2 items-center justify-center font-mono text-lg uppercase'>
      <ChartSquareBarIcon className='w-6 h-6 block mr-3' />
      <CountUp end={listingsNo} duration={2.75} delay={0} className='mr-2' />
      <p>In {countyName}</p>
    </div>
    <div className='bg-gray-100 p-2 md:p-5 h-auto w-auto '>
      <div className=''>
        <Listings
          listings={listings}
          fetchMore={fetchMore}
          loading={loading}
          currentFilter={currentFilter}
          numToShow={numToShow}
        />
      </div>
    </div>
  </>
  )
  
}
