import { useNavigate, useLocation } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/solid';
/* This example requires Tailwind CSS v2.0+ */
export default function Example() {
  const navigate = useNavigate();
  return (
    <div className='flex flex-row bg-orange justify-center my-2'>
      <button
        type='button'
        className='relative inline-flex items-center px-2 py-1 md:px-4 md:py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500'
        onClick={() => navigate('/create-listing')}
      >
        <PlusIcon className='-ml-1 mr-2 h-5 w-5' aria-hidden='true' />
        <span>Add Listing</span>
      </button>
    </div>
  );
}
