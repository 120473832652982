import React from "react";
import UserProfileFavouriteItem from "../components/UserProfileFavouriteItem";
import Spinner from "./Spinner";
import { useNavigate } from 'react-router-dom';

const UserFavourites = ({ favorites, getFavoriteListing, loading }) => {
    const navigate = useNavigate()
  return (
    <div>
      {loading ? (
        <Spinner />
      ) : favorites.length === 0 ? (
        <div className="border-black border-4 bg-blue-100 p-5 md:w-1/2 w-full mx-auto flex-wrap rounded-lg">
          <p className="md:text-lg text-sm font-bold text-gray-700">
            You haven't favourited any listings yet - just click the like ❤️
            button on the top right of a listing!
          </p>
          <div className='inline-flex rounded-md shadow mt-4'>
            <a
              onClick={() => navigate('/explore')}
              className='cursor-pointer inline-flex items-center justify-center px-5 py-1 border border-transparent text-base font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-700 '
            >
              Explore
            </a>
          </div>
          {/* {listings.length > 0 && (
          <div className="flex flex-row">
          <p className="flex md:text-lg text-sm text-gray-700">
            Thats x% of total contributions! 
          </p>
          </div>
        )} */}
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full mt-6 mx-auto">

        {favorites.map((listingID) => (
          <UserProfileFavouriteItem
            key={listingID}
            listingID={listingID}
            getFavoriteListing={getFavoriteListing}
          />
        ))}
        </div>
      )}
      </div>
  );
};

export default UserFavourites;
