import React, { Fragment } from 'react';
import logo from '../assets/g5954.png';

const Spinner = () => {
  return (
    <Fragment>
      <div className='flex flex-col h-screen items-center justify-center' style={{ height: 'calc(100vh / 3)' }}>
        <img 
          src={logo} 
          alt='Loading' 
          style={{ width: '64px', display: 'block' }}
        />
        <h1 className='mt-4'>Loading..</h1>
      </div>
    </Fragment>
  );
};

export default Spinner;
