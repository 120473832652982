import React from "react";
import { Link } from "react-router-dom";
import ImageLoader from "./ImageLoader";
import { MailIcon } from "@heroicons/react/outline";

const UserProfileContributionItem = ({ listing }) => {
  return (
    <div key={listing.id}>
      <Link
        to={`/category/${listing.data.type}/${listing.id}`}
        className="bg-white transform overflow-hidden rounded-md flex flex-row gap-4 shadow-lg hover:-translate-y-0.5 cursor-pointer h-48"
      >
        <div className="w-1/2">
          <ImageLoader
            src={
              listing.data.thumbnailUrls
                ? listing.data.thumbnailUrls
                : listing.data.imgUrls
            }
            className="object-cover w-full h-full rounded-md border border-2 border-gray-800"
            alt="Thumbnail"
          />
        </div>

        <div className="flex flex-col justify-between w-1/2 py-4 px-2">
          <div className="flex flex-col">
            {/* <p className='text-sm sm:text-md font-light'>{listing.data.address}</p> */}
            <p className="text-sm sm:text-md font-light">{listing.data.town}</p>
            <p className="text-sm sm:text-lg font-bold">
              {listing.data.county_region === "Northern Ireland"
                ? listing.data.town
                : listing.data.county_region}
            </p>
          </div>

          <div className="flex flex-row mt-3 justify-center ">
            {listing.data.registered.length >= 1 && (
              <div className="flex flex-row rounded-lg">
                <MailIcon className="w-6 h-6 block text-pink-600" />
                <h1 className="font-black ml-1">
                  {listing.data.registered.length}
                </h1>
              </div>
            )}
            {listing.data.likes >= 1 ? (
              <div className="flex flex-row rounded-lg">
                <h1 className="font-black ml-1">❤️ {listing.data.likes}</h1>
              </div>
            ) : (null)}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default UserProfileContributionItem;
