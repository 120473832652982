import React from 'react';
import { toast } from 'react-toastify';
import { ShareIcon } from '@heroicons/react/outline';

const ShareButton = () => {
  const shareListing = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Doghouse! #DerelictIreland #GenerationRent',
          url: `${window.location.href}`,
        })
        .then(() => {
          toast.success('Thanks for sharing!');
        })
        .catch(console.error);
    } else {
      navigator.clipboard.writeText(window.location.href)
      toast.success('Saved to clipboard')
    }
  };
  return (
    <button
      type='button'
      className='relative inline-flex items-center px-2 py-1 md:px-4 md:py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500'
      onClick={shareListing}
    >
      <ShareIcon className='-ml-1 mr-2 h-5 w-5' aria-hidden='true' />
      <span>Share</span>
    </button>
  );
};

export default ShareButton;
