import React from "react";
import UserProfileContribution from "./UserProfileContributionItem";
import { MailIcon } from "@heroicons/react/solid";
import Spinner from "./Spinner";

const UserContributions = ({ listings, totalRegistrations, loading }) => {
  return (
    <>
      <div className="border-black border-4 bg-blue-100 p-5 max-w-max md:w-1/3 w-full mx-auto flex-wrap rounded-lg">
        <p className="md:text-lg text-sm font-bold text-gray-700">
          {loading
            ? <Spinner/>
            : listings.length > 0
            ? `You've posted ${listings.length} properties - thank you!🫡`
            : "You don't have any listings yet. Click below to get started!"}
        </p>
        {listings.length > 0 && (
          <div className="flex flex-row">
          <p className="flex md:text-lg text-sm text-gray-700">
            {totalRegistrations === 0
              ? `No one has registered interest in these yet. Be the first!🎉`
              : totalRegistrations === 1
              ? `${totalRegistrations} person has registered interest in these!🎉`
              : `${totalRegistrations} people have registered interest in these!`} <MailIcon className='w-4 h-4 block text-pink-600' />
          </p>
          
          </div>
        )}
        
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full mt-6">
        {listings.map((listing) => (
          <UserProfileContribution listing={listing} key={listing.id} />
        ))}
      </div>
    </>
  );
};

export default UserContributions;
