import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import ImageLoader from './ImageLoader';
import LikeButton from './LikeButton';

const FavouriteItem = ({ listingID, getFavoriteListing }) => {
  const [listing, setListing] = useState(null);

  useEffect(() => {
    const fetchListingData = async () => {
      const fetchedListing = await getFavoriteListing(listingID);
      setListing(fetchedListing);
    };

    fetchListingData();
  }, [listingID, getFavoriteListing]);

  if (!listing) {
    return null; // Render a loading state or placeholder if necessary
  }

  return (
    <div key={listing.id}>
    <Link
      to={`/category/${listing.data.type}/${listing.id}`}
      className='bg-white transform overflow-hidden rounded-md flex flex-row gap-4 shadow-lg hover:-translate-y-0.5 cursor-pointer h-48'
    >
      <div className='w-1/2'>
        <ImageLoader
          src={listing.data.thumbnailUrls ? listing.data.thumbnailUrls : listing.data.imgUrls}
          className='object-cover w-full h-full rounded-md border border-2 border-gray-800'
          alt="Thumbnail"
        />
      </div>

      <div className='flex flex-col justify-between w-1/2 py-4 px-2'>
        <div className='flex flex-col'>
          <p className='text-sm sm:text-md font-light'>{listing.data.town}</p>
          <p className='text-sm sm:text-lg font-bold'>
            {listing.data.county_region === 'Northern Ireland'
              ? listing.data.town
              : listing.data.county_region}
          </p>
        </div>

        {/* <div className='flex flex-row mt-3'>
          {listing.data.registered.length >= 1 && (
            <div className='flex flex-row items-start rounded-lg'>
              <MailIcon className='w-6 h-6 block text-pink-600' />
              <h1 className='font-black ml-1'>{listing.data.registered.length}</h1>
            </div>
          )}

          <div className='flex flex-wrap items-center ml-auto'>
            {listing.data.isDerelict && (
              <span className='inline-flex items-center px-2 py-0.5 rounded-lg text-xs md:text-xs font-medium bg-red-100 text-red-800 border border-black mx-0.5'>
                🏚️
              </span>
            )}
            {listing.data.isVacant && (
              <span className='inline-flex items-center px-2 py-0.5 rounded-lg text-xs md:text-xs font-medium bg-green-100 text-green-800 border border-black mx-0.5'>
                🏝️
              </span>
            )}
            {listing.data.ownerKnown ? (
              <span className='inline-flex items-center px-2 py-0.5 rounded-lg text-xs md:text-xs font-medium bg-blue-100 text-blue-800 border border-black mx-0.5'>
                😃
              </span>
            ) : (
              <span className='inline-flex items-center px-2 py-0.5 rounded-lg text-xs md:text-xs font-medium bg-purple-100 text-purple-800 border border-black mx-0.5'>
                ❓
              </span>
            )}
          </div>
        </div> */}
      </div>
      {/* add like button */}
      <div className='absolute bottom-2 right-2'>
        <LikeButton listingID={listing.id} />
      </div>
    </Link>
  </div>
  );
};

export default FavouriteItem;
