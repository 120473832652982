import React from 'react';
import { ArrowUpIcon } from '@heroicons/react/outline';

const ScrollTopButton = () => {
  const scrollTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Oper
  };
  return (
    <button
      type='button'
      className='relative inline-flex items-center px-2 py-1 md:px-4 md:py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500'
      onClick={scrollTop}
    >
      <ArrowUpIcon className='-ml-1 mr-2 h-5 w-5' aria-hidden='true' />
      <span>Scroll To Top</span>
    </button>
  );
};

export default ScrollTopButton;
