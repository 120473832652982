import { useState } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { getAuth } from 'firebase/auth';

// Here we are using the HTML Canvas API to resize the image
const resizeImage = (file, maxWidth = 800, maxHeight = 800, quality = 0.7, format = 'image/jpeg') => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = () => {
            const canvas = document.createElement('canvas');
            const scaleSize = Math.min(maxWidth / image.width, maxHeight / image.height);
            canvas.width = image.width * scaleSize;
            canvas.height = image.height * scaleSize;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

            canvas.toBlob(
                (blob) => {
                    resolve(blob);
                },
                format,
                quality
            );
        };
        image.onerror = reject;
    });
};

const resizeAndCropImage = (file, width = 1005, height = 670, quality = 0.9, format = 'image/jpeg') => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = () => {
            const canvas = document.createElement('canvas');
            const scale = Math.max(width / image.width, height / image.height);
            const [scaledWidth, scaledHeight] = [image.width * scale, image.height * scale];

            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');

            // Make sure the image is centered in the canvas
            const dx = (width - scaledWidth) / 2;
            const dy = (height - scaledHeight) / 2;
            ctx.drawImage(image, dx, dy, scaledWidth, scaledHeight);

            canvas.toBlob(
                (blob) => {
                    resolve(blob);
                },
                format,
                quality
            );
        };
        image.onerror = reject;
    });
};

const resizeForSocialMediaCard = (file, width = 1200, height = 630, quality = 0.7, format = 'image/jpeg') => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const scale = Math.max(width / image.width, height / image.height);
        const [scaledWidth, scaledHeight] = [image.width * scale, image.height * scale];
  
        canvas.width = width;
        canvas.height = height;
  
        const ctx = canvas.getContext('2d');
  
        // Make sure the image is centered in the canvas
        const dx = (width - scaledWidth) / 2;
        const dy = (height - scaledHeight) / 2;
        ctx.drawImage(image, dx, dy, scaledWidth, scaledHeight);
  
        canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          format,
          quality
        );
      };
      image.onerror = reject;
    });
  };

export const useImageUpload = () => {
    const auth = getAuth();
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const uploadDerelictImage = async (image, newDerelictListingID) => {
        setLoading(true);

        const storage = getStorage();
        //const fileName = `${saleId}-${uuidv4()}`;
        const fileName = `${newDerelictListingID}-${uuidv4()}`;
        const thumbnailName = `thumbnail-${newDerelictListingID}-${uuidv4()}`;
        const socialMediaCardName = `social-media-card-${newDerelictListingID}`;


        // Resizing and compressing the original image
        const resizedBlob = await resizeImage(image);
        const thumbnailBlob = await resizeAndCropImage(image, 600, 600);
        const socialMediaCardBlob = await resizeForSocialMediaCard(image, 1200, 630);


        const imageStoragePath = `listings/${newDerelictListingID}/${fileName}`;
        const thumbnailStoragePath = `listings/${newDerelictListingID}/${thumbnailName}`;
        const socialMediaCardStoragePath = `listings/${newDerelictListingID}/${socialMediaCardName}`;


        const storageRef = ref(storage, imageStoragePath);
        const thumbnailStorageRef = ref(storage, thumbnailStoragePath);
        const socialMediaCardStorageRef = ref(storage, socialMediaCardStoragePath);


        // Upload the resized image
        const uploadTask = uploadBytesResumable(storageRef, resizedBlob);
        const thumbnailUploadTask = uploadBytesResumable(thumbnailStorageRef, thumbnailBlob);
        const socialMediaCardUploadTask = uploadBytesResumable(socialMediaCardStorageRef, socialMediaCardBlob);



        // Upload completed successfully, now we can get the download URL
        const imageUrl = await new Promise((resolve, reject) => {
            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setProgress(progress);
                },
                (error) => {
                    console.log(error);
                    reject(error);
                    setLoading(false);
                },
                async () => {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    resolve(downloadURL);
                    setLoading(false);
                }
            );
        });

        // Upload completed successfully, now we can get the download URL
        const thumbnailUrl = await new Promise((resolve, reject) => {
            thumbnailUploadTask.on('state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Thumbnail upload is ' + progress + '% done');
                },
                (error) => {
                    console.log('Thumbnail upload failed:', error);
                    reject(error);
                },
                async () => {
                    const downloadURL = await getDownloadURL(thumbnailUploadTask.snapshot.ref);
                    resolve(downloadURL);
                    console.log('Thumbnail uploaded successfully');
                }
            );
        });
        // Upload completed successfully, now we can get the download URL
    const socialMediaCardUrl = await new Promise((resolve, reject) => {
        socialMediaCardUploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Social media card upload is ' + progress + '% done');
          },
          (error) => {
            console.log('Social media card upload failed:', error);
            reject(error);
          },
          async () => {
            const downloadURL = await getDownloadURL(socialMediaCardUploadTask.snapshot.ref);
            resolve(downloadURL);
            console.log('Social media card uploaded successfully');
          }
        );
      });

        return { imageUrl, thumbnailUrl, socialMediaCardUrl };
    };

    return {
        uploadDerelictImage,
        loading,
        progress,
    };
};
