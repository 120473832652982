/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { HomeIcon } from '@heroicons/react/outline';
import { useAuthStatus } from '../hooks/useAuthStatus';
import { toast } from 'react-toastify';
import { MailIcon } from '@heroicons/react/solid';

export default function ClaimModal(props) {
  const [open, setOpen] = useState(true);
  //Check if user is logged in
  const { loggedIn, loading } = useAuthStatus();
  //component level state for form
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    number: '',
  });

  const { email, name, number } = formData;

  const cancelButtonRef = useRef(null);

  const { showClaimModal, handleCancel, handleSubmit, listing } = props;

  // handle form input
  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  // Form onSubmit
  const onSubmit = (e) => {
    e.preventDefault();

    const interestToAdd = {
      name: name,
      email: email,
      number: number,
    };
    // check if user is  logged in , otherwise tell them to
    if (!loggedIn) {
      toast('Please Log In');
    } else {
      handleSubmit(interestToAdd);
    }
  };

  return (
    <Transition.Root show={showClaimModal} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed z-10 inset-0 overflow-y-auto'
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
              <div>
                <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100'>
                  <HomeIcon
                    className='h-6 w-6 text-green-600'
                    aria-hidden='true'
                  />
                </div>
                <div className='mt-3 text-center sm:mt-5'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg leading-6 font-medium text-gray-900'
                  >
                    Claim For Sale
                  </Dialog.Title>

                  {listing.registered.length == 1 ? (
                    <div className='flex flex-row w-full my-1  rounded-lg  justify-center'>
                      <MailIcon className='w-6 h-6 block mx-2 text-pink-600' />
                      <h1 className='font-thin'>
                        {listing.registered.length} Buyer Waiting
                      </h1>
                    </div>
                  ) : listing.registered.length >= 2 ? (
                    <div className=' flex flex-row w-full items-start my-1 rounded-lg justify-center'>
                      <MailIcon className='w-6 h-6 block mx-2 text-pink-600' />
                      <h1 className='font-thin'>
                        {listing.registered.length} Buyers Waiting
                      </h1>
                    </div>
                  ) : null}
                  <div className='mt-2'>
                    <p className='text-sm text-gray-500'>
                      Full Feature Coming Next Month.
                    </p>
                    <br />

                    <p className='text-sm text-gray-500'>
                      Please contact us at{' '}
                      <a
                        className='underline'
                        href='mailto:info@dog-house.ie?subject=Claim For Sale Enquiry'
                      >
                        info@dog-house.ie
                      </a>{' '}
                      and let us know how we can help you set up the sale of
                      your property.
                    </p>
                  </div>
                  <div className='mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'>
                    {' '}
                    <button
                      type='button'
                      className='disabled w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm cursor-not-allowed'
                    >
                      Claim
                    </button>
                    <button
                      type='button'
                      className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm'
                      onClick={() => handleCancel()}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
