import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <footer>
      <div class='mx-auto py-2 px-4 overflow-hidden sm:px-6 lg:px-8 bg-gray-100'>
        <div class='mt-8 flex justify-center space-x-6'>
          <a
            href='https://twitter.com/doghouse_homes'
            target='_blank'
            class='text-gray-400 hover:text-gray-500'
          >
            <span class='sr-only'>Twitter</span>
            <svg
              class='h-6 w-6'
              fill='currentColor'
              viewBox='0 0 24 24'
              aria-hidden='true'
            >
              <path d='M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84' />
            </svg>
          </a>

            {/* TODO: Add mailto symbol here to contact address  */}
          <a
            target='_blank'
            className='text-gray-400 hover:text-gray-500 cursor-pointer'
            onClick={() => navigate('/privacy')}
          >
            <span class='sr-only'>Privacy Policy</span>
            Privacy Policy
          </a>
        </div>
        <p class='mt-8 text-center text-sm font-thin text-gray-600'>
          &copy; 2023 Doghouse
        </p>
      </div>
    </footer>
  );
};

export default Footer;
