import React from 'react';
import { GoogleMap, LoadScript, Marker, Circle } from '@react-google-maps/api';

// map container style
const containerStyle = {
  width: 'inherit',
  height: 'inherit',
};

// Circle options
const options = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 750,
  zIndex: 1,
};
const MiniMap = (props) => {
  const { geolocation } = props.listing;

  return (
    <>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={geolocation}
          zoom={13}
          options={{
            mapTypeControl: false,
            disableDefaultUI: true,
            zoomControl: true,
          }}
        >
          {/* <Marker position={geolocation} /> */}
          <Circle
            // required
            center={geolocation}
            // required
            options={options}
          />
        </GoogleMap>
      </LoadScript>
    </>
  );
};

export default MiniMap;
