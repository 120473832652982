import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc, increment, arrayRemove , arrayUnion, setDoc} from 'firebase/firestore';
import { db , firebase} from '../firebase.config';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { HeartIcon as HeartIconSolid } from '@heroicons/react/solid';
import { HeartIcon as HeartIconOutline } from '@heroicons/react/outline';
import { toast } from 'react-toastify';


const LikeButton = ({ listingID }) => {
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [likes, setLikes] = useState(0);
  const [liked, setLiked] = useState(false);
  const [updating, setUpdating] = useState(false);
    const [userRef, setUserRef] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
        setUserRef(doc(db, 'users', authUser.uid)); // Set userRef to the user document in Firestore
      } else {
        setUser(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {

    fetchLikes();
  }, [listingID]);

  const fetchLikes = async () => {
    const listingRef = doc(db, 'listings', listingID);
    const listingDoc = await getDoc(listingRef);
    if (listingDoc.exists()) {
      const data = listingDoc.data();
      const likesCount = data.likes || 0;
      setLikes(likesCount);
    }
  };

  useEffect(() => {
    const checkLiked = async () => {
        if (user) {
          const userRef = doc(db, 'users', user.uid);
          const userSnapshot = await getDoc(userRef);
      
          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            const userFavs = userData.userFavs || [];
            setLiked(userFavs.includes(listingID));
          }
        }
      };
      

    checkLiked();
  }, [listingID, user]);

  const handleLike = async (e) => {
    e.preventDefault()
    e.stopPropagation();
    if (user && !updating) {
    try {
        setUpdating(true); // Set updating state to true
      const listingRef = doc(db, 'listings', listingID);
      if (liked) {
        // Remove like
        await Promise.all([
            updateDoc(listingRef, {
              likes: increment(-1),
              likedBy: arrayRemove(user.uid),
            }),
            updateDoc(userRef, {
              userFavs: arrayRemove(listingID),
            }),
            ]);
          // Show toast notification when added to favorites
        toast.info('Removed From Favourites', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
      } else {
        await Promise.all([
            updateDoc(listingRef, {
              likes: increment(1),
              likedBy: arrayUnion(user.uid),
            }),
            updateDoc(userRef, {
              userFavs: arrayUnion(listingID),
            }),
          ]);

        toast.success('Added to Favourites', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      }

      setLiked(!liked); // Toggle the liked state
      fetchLikes(); // Fetch the updated likes count
      setUpdating(false); // Set updating state to false
    } catch (error) {
      toast.error('Could not like listing');
      setUpdating(false); // Set updating state to false
    }
} else {
    toast.error('Please sign in to like a listing');
}
  };
  return (
    <div>
    <button
      onClick={handleLike}
      className=" transform hover:-translate-y-0.5 cursor-pointer"
    >
      {liked ? (
        <HeartIconSolid className="h-10 w-10 text-pink-500 hover:text-gray-200" />
      ) : (
        <HeartIconOutline className="h-8 w-8 text-gray-500 hover:text-black " />
      )}
    </button>
    <h1 className="text-center text-gray-500 text-xs">{likes}</h1>
  </div>
  );
};

export default LikeButton;
