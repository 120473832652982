import React from "react";
import { useEffect, useState } from "react";
import { useAuthStatus } from "../hooks/useAuthStatus";
import {
  collection,
  getDocs,
  orderBy,
  query,
  where,
  getDoc,
  doc,
} from "firebase/firestore";
import { db } from "../firebase.config";
import { toast } from "react-toastify";
import Create from "../components/Create";
import UserProfileFavouriteItem from "../components/UserProfileFavouriteItem";
import UserProfileContribution from "../components/UserProfileContributionItem";
import { userProfileStyle } from "./userProfileStyle.css";
import UserFavourites from "../components/UserFavourites";
import UserContributions from "../components/UserContributions";

export const UserProfile = () => {
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [userRef, setUserRef] = useState(null);
  const [favorites, setFavorites] = useState([]);
  const [totalRegistrations, setTotalRegistrations] = useState(0);
  const [activeTab, setActiveTab] = useState("contributions");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const [listingsNo, setListingsNo] = useState(0);
  const { userID, username, userEmail } = useAuthStatus();

  // setUserRef(doc(db, 'users', userID));
  useEffect(() => {
    const fetchListings = async () => {
      setLoading(true);
      try {
        const listingsRef = collection(db, "listings");

        const commonConditions = [
          orderBy("timestamp", "desc"),
          where("userRef", "==", userID),
        ];
        let q = query(listingsRef, ...commonConditions);

        const querySnap = await getDocs(q);

        let listings = [];

        querySnap.forEach((doc) => {
          const data = doc.data();
          listings.push({
            id: doc.id,
            data: {
              ...data,
              registeredCount: data.registered.length,
            },
          });
        });

        setListings(listings);
        setTotalRegistrations(
          listings.reduce(
            (total, listing) => total + listing.data.registeredCount,
            0
          )
        );
      } catch (error) {
        toast.error("Could Not Get Listings");
      } finally {
        setLoading(false);
      }
    };

    const fetchFavorites = async () => {
      try {
        const userRef = doc(db, "users", userID);
        const userSnapshot = await getDoc(userRef);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          const userFavs = userData?.userFavs || []; // Add null check here
          userFavs.forEach((fav) => {
            favorites.push(fav);
          });
        }
      } catch (error) {
        toast.error("Could Not Get Favorites");
      }
    };

    if (userID) {
      fetchFavorites();
    }
    fetchListings();
  }, [userID]); // Include userID in the dependency array

  const getFavoriteListing = async (listingID) => {
    try {
      const listingRef = doc(db, "listings", listingID);
      const listingSnapshot = await getDoc(listingRef);

      if (listingSnapshot.exists()) {
        const listingData = listingSnapshot.data();
        return {
          id: listingSnapshot.id,
          data: listingData,
        };
      }
    } catch (error) {
      toast.error("Could Not Fetch Listing");
    }

    return null;
  };

  return (
    <div>
      <div className="p-4 flex flex-col items-center text-center justify-center bg-gray-100">
        <div className="flex">
          <button
            className={`px-4 py-2 mx-2 ${
              activeTab === "favourites"
                ? "text-white shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none"
                : "bg-gray-200 text-gray-600 hover:text-white shadow-sm text-sm font-medium rounded-md text-white hover:bg-indigo-600"
            }`}
            onClick={() => handleTabChange("favourites")}
          >
            Favourites
          </button>
          <button
            className={`px-2 py-2 mx-2 ${
              activeTab === "contributions"
                ? "bg-blue-500 text-white shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600"
                : "bg-gray-200 text-gray-600 hover:text-white shadow-sm text-sm font-medium rounded-md text-white hover:bg-indigo-600"
            }`}
            onClick={() => handleTabChange("contributions")}
          >
            Contributions
          </button>
        </div>
        <h2 className="text-xl font-bold text-gray-800 bg-gray-100 py-4">
          Welcome, {username ? username : userEmail} 🏠
        </h2>
        <div className="w-full" >
          {activeTab === "favourites" && (
            <UserFavourites
              favorites={favorites}
              getFavoriteListing={getFavoriteListing}
              loading={loading}
            />
          )}
          {activeTab === "contributions" && (
            <UserContributions
              listings={listings}
              totalRegistrations={totalRegistrations}
            />
          )}
        </div>
      </div>
    </div>
  );
};
